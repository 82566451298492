/**
 * JS column
 */
(function ($) {
	var defaults = {
		columns: 4,
		classname: 'column',
		min: 1
	};

	$.fn.autocol = function (params) {
		var options = $.extend({}, defaults, params);
		return this.each(function () {
			var els = $(this).find('> li').not('li.sf-all-list');
			var dimension = els.length;
			if (dimension > 0) {
				var elCol = Math.ceil(dimension / options.columns);
				if (elCol < options.min) {
					elCol = options.min;
				}
				var start = 0;
				var end = elCol;
				for (var i = 0; i < options.columns; i++) {
					if ((i + 1) == options.columns) {
						els.slice(start, end).wrapAll('<div class="' + options.classname + ' last" />');
					} else {
						els.slice(start, end).wrapAll('<div class="' + options.classname + '" />');
					}
					start = start + elCol;
					end = end + elCol;
				}
			}
		});
	};

})(jQuery);